/* eslint-disable import/no-cycle */
import {IWidgetController} from '@wix/native-components-infra/dist/src/types/types';
import {CurrencyService} from '../../services/CurrencyService';
import {CurrencyConverterStore} from './currencyConverterStore';
import {IContext, ICurrencyConverterWidgetControllerConfig} from './createViewerScript';

export function createAppController(
  controllerConfig: ICurrencyConverterWidgetControllerConfig,
  context: IContext,
  reportError
): IWidgetController {
  const {setProps} = controllerConfig;

  return {
    pageReady() {
      const {siteStore} = context;
      const currencyService = new CurrencyService(siteStore.httpClient, siteStore.resolveAbsoluteUrl.bind(siteStore));

      const currencyConverterStore = new CurrencyConverterStore({
        siteStore,
        currencyService,
        updateComponent: setProps,
      });

      return currencyConverterStore.setInitialState(reportError);
    },
  };
}
